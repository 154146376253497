import { Locale } from '../../common/interfaces/locate.js';
import { el, mount, svg } from '../../lib/redom.es.min.js';
import { VippsHurtigkasseController, IConfirmedOrderResponse } from './vipps-hurtigkasse-controller.js';
import { determineTranslation } from "../../utils/language.js";
// TODO: The initial attribute btn-shape is going to be removed and new parameter svg-shape will be craeted.
// Reason: avoid supporting extra assets for each button shape and vector graphic insite it.
// Approved by Vipps that we can twick button border radius at different site apps. But we are not alowed to change vector inside.
// Check list of sites using btn-shape from UMWC-6394. When they get an update = their custom styles can be removed. Rebuilded by front-enders site builder tool!

export enum PayWithVippsButtonSvgShape {
    Logo = 'Logo',
    TextAndLogo = 'TextAndLogo',
}

const PayWithVippsButtonShape = { // Backwards compatibility, can be removed when sites from UMWC-6394 print screen are updated to new version.
    Pill140: PayWithVippsButtonSvgShape.Logo,
    Pill210: PayWithVippsButtonSvgShape.TextAndLogo,
    Pill250: PayWithVippsButtonSvgShape.TextAndLogo,
    Rect140: PayWithVippsButtonSvgShape.Logo,
    Rect210: PayWithVippsButtonSvgShape.TextAndLogo,
    Rect250: PayWithVippsButtonSvgShape.TextAndLogo,
} as const;

const sharedLogo = () => svg(
    "svg",
    {
        viewBox: "0 0 64 16",
        width: "64",
        height: "16",
    },
    svg("path", {fill: "#fff", style: "fill-rule: evenodd", d: "m64,3.84c-.72-2.75-2.47-3.84-4.86-3.84-1.93,0-4.36,1.09-4.36,3.72,0,1.7,1.17,3.03,3.09,3.37l1.81.32c1.23.22,1.58.69,1.58,1.31,0,.71-.76,1.11-1.89,1.11-1.48,0-2.41-.53-2.55-2l-2.61.4c.41,2.85,2.96,4.02,5.27,4.02,2.18,0,4.51-1.25,4.51-3.78,0-1.72-1.05-2.97-3-3.33l-2-.36c-1.11-.2-1.48-.75-1.48-1.27,0-.67.72-1.09,1.71-1.09,1.25,0,2.14.42,2.18,1.82l2.61-.4ZM5.92,8.16L8.64.3h3.19l-4.73,11.66h-2.37L0,.3h3.19l2.74,7.86ZM22.61,3.64c0,.93-.74,1.58-1.6,1.58s-1.6-.65-1.6-1.58.74-1.58,1.6-1.58,1.6.65,1.6,1.58h0Zm.49,4.12c-1.07,1.37-2.2,2.32-4.2,2.32-2.04,0-3.62-1.21-4.85-2.99-.49-.73-1.26-.89-1.81-.51-.51.36-.64,1.13-.16,1.8,1.71,2.57,4.07,4.06,6.83,4.06,2.53,0,4.51-1.21,6.05-3.23.58-.75.56-1.51,0-1.94-.51-.4-1.28-.26-1.85.48Zm7.1-1.66c0,2.38,1.4,3.64,2.96,3.64,1.48,0,3-1.17,3-3.64s-1.52-3.6-2.98-3.6c-1.58,0-2.98,1.11-2.98,3.6Zm0-4.18V.32h-2.9v15.68h2.9v-5.58c.97,1.29,2.22,1.84,3.64,1.84,2.65,0,5.25-2.06,5.25-6.3S36.39,0,34.09,0C32.26,0,31,.83,30.2,1.92Zm13.93,4.18c0,2.38,1.4,3.64,2.96,3.64,1.48,0,3-1.17,3-3.64s-1.52-3.6-2.98-3.6c-1.58,0-2.98,1.11-2.98,3.6h0Zm0-4.18V.32h-2.9v15.68h2.9v-5.58c.97,1.29,2.22,1.84,3.64,1.84,2.65,0,5.25-2.06,5.25-6.3S50.32,0,48.02,0c-1.83,0-3.09.83-3.89,1.92Z"})
);

type SvgBtnType = {
    [key in Locale]: {
        [key in PayWithVippsButtonSvgShape] : () => SVGElement
    }
}

const btnSvg: SvgBtnType = {
    [Locale.No]: {
        [PayWithVippsButtonSvgShape.Logo]: sharedLogo,
        [PayWithVippsButtonSvgShape.TextAndLogo]: () => svg(
            "svg",
            {
                viewBox: "0 0 157.61 17.69",
                width: "158",
                height: "20",
            },
            svg("path", {fill: "#fff", d: "m5.57,13.69c2.78,0,4.48-1.41,4.48-3.7,0-1.7-1.21-2.96-2.96-3.13v-.16c1.31-.21,2.32-1.42,2.32-2.79,0-2.01-1.49-3.25-3.97-3.25H0v13.03h5.57ZM2.33,2.48h2.55c1.41,0,2.23.68,2.23,1.84s-.88,1.83-2.53,1.83h-2.25v-3.68Zm0,9.4v-4.07h2.62c1.78,0,2.72.7,2.72,2.02s-.91,2.05-2.63,2.05h-2.71Zm16.27-.89c-.33.74-1.09,1.16-2.2,1.16-1.47,0-2.42-1.05-2.48-2.72v-.12h6.9v-.71c0-3.1-1.69-4.97-4.51-4.97s-4.64,2-4.64,5.17,1.75,5.09,4.66,5.09c2.33,0,3.98-1.12,4.39-2.9h-2.12Zm-2.29-5.62c1.35,0,2.23.95,2.28,2.45h-4.65c.1-1.48,1.04-2.45,2.38-2.45Zm7.28-3.97v2.48h-1.56v1.74h1.56v5.45c0,1.91.9,2.66,3.17,2.66.43,0,.85-.04,1.17-.1v-1.72c-.27.03-.44.05-.76.05-.94,0-1.35-.44-1.35-1.43v-4.91h2.11v-1.74h-2.11V1.4h-2.23Zm9.1,12.46c1.29,0,2.38-.56,2.93-1.52h.15v1.35h2.16v-6.75c0-2.09-1.43-3.32-3.97-3.32-2.35,0-3.98,1.1-4.16,2.83h2.11c.21-.68.91-1.05,1.94-1.05,1.21,0,1.86.55,1.86,1.54v.85l-2.55.15c-2.4.14-3.75,1.17-3.75,2.94s1.36,2.96,3.27,2.96Zm.66-1.73c-.99,0-1.71-.5-1.71-1.35s.59-1.28,1.84-1.36l2.23-.15v.8c0,1.17-1.01,2.06-2.37,2.06Zm7.17,1.56h2.24V0h-2.24v13.69Zm9.61,0h2.24v-6.01c0-1.23.82-2.16,1.93-2.16s1.79.67,1.79,1.76v6.4h2.18v-6.17c0-1.13.77-2,1.93-2s1.81.64,1.81,1.92v6.24h2.23v-6.79c0-2.05-1.19-3.27-3.17-3.27-1.37,0-2.51.7-3,1.78h-.15c-.42-1.09-1.36-1.78-2.73-1.78s-2.32.67-2.75,1.78h-.15v-1.59h-2.16v9.87Zm23.01-2.7c-.33.74-1.09,1.16-2.2,1.16-1.47,0-2.42-1.05-2.48-2.72v-.12h6.9v-.71c0-3.1-1.69-4.97-4.51-4.97s-4.64,2-4.64,5.17,1.75,5.09,4.66,5.09c2.33,0,3.98-1.12,4.39-2.9h-2.12Zm-2.29-5.62c1.35,0,2.23.95,2.28,2.45h-4.65c.1-1.48,1.04-2.45,2.38-2.45Zm10.14,8.48c1.37,0,2.51-.64,3.07-1.73h.15v1.56h2.17V0h-2.24v5.39h-.15c-.52-1.08-1.64-1.73-3-1.73-2.49,0-4.07,1.97-4.07,5.09s1.56,5.1,4.07,5.1Zm.7-8.33c1.54,0,2.48,1.24,2.48,3.23s-.94,3.23-2.48,3.23-2.47-1.21-2.47-3.23.94-3.23,2.47-3.23Z"}),
            svg("path", {fill: "#fff", style: "fill-rule: evenodd", d: "m157.61,5.53c-.72-2.75-2.47-3.84-4.85-3.84-1.93,0-4.36,1.09-4.36,3.72,0,1.7,1.17,3.03,3.09,3.37l1.81.32c1.23.22,1.58.69,1.58,1.31,0,.71-.76,1.11-1.89,1.11-1.48,0-2.41-.53-2.55-2l-2.61.4c.41,2.85,2.96,4.02,5.27,4.02,2.18,0,4.51-1.25,4.51-3.78,0-1.72-1.05-2.97-3-3.33l-2-.36c-1.11-.2-1.48-.75-1.48-1.27,0-.67.72-1.09,1.71-1.09,1.26,0,2.14.42,2.18,1.82l2.61-.4Zm-58.07,4.32l2.71-7.86h3.19l-4.73,11.66h-2.37l-4.73-11.66h3.19l2.74,7.86Zm16.68-4.52c0,.93-.74,1.58-1.6,1.58s-1.6-.65-1.6-1.58.74-1.58,1.6-1.58,1.6.65,1.6,1.58h0Zm.49,4.12c-1.07,1.37-2.2,2.32-4.2,2.32-2.04,0-3.62-1.21-4.85-2.99-.49-.73-1.26-.89-1.81-.51-.51.36-.64,1.13-.16,1.8,1.71,2.57,4.07,4.06,6.83,4.06,2.53,0,4.51-1.21,6.05-3.23.58-.75.56-1.51,0-1.94-.51-.4-1.27-.26-1.85.48h0Zm7.1-1.66c0,2.38,1.4,3.64,2.96,3.64,1.48,0,3-1.17,3-3.64s-1.52-3.6-2.98-3.6c-1.58,0-2.98,1.11-2.98,3.6Zm0-4.18v-1.6h-2.9v15.68h2.9v-5.58c.97,1.29,2.22,1.84,3.64,1.84,2.65,0,5.25-2.06,5.25-6.3s-2.7-5.96-5-5.96c-1.83,0-3.09.83-3.89,1.92h0Zm13.93,4.18c0,2.38,1.4,3.64,2.96,3.64,1.48,0,3-1.17,3-3.64s-1.52-3.6-2.98-3.6c-1.58,0-2.98,1.11-2.98,3.6h0Zm0-4.18v-1.6h-2.9v15.68h2.9v-5.58c.97,1.29,2.22,1.84,3.64,1.84,2.65,0,5.25-2.06,5.25-6.3s-2.7-5.96-5-5.96c-1.83,0-3.09.83-3.89,1.92h0Z"})
        )
    },
    [Locale.En]: {
        [PayWithVippsButtonSvgShape.Logo]: sharedLogo,
        [PayWithVippsButtonSvgShape.TextAndLogo]: () => svg(
            "svg",
            {
                viewBox: "0 0 143.63 18.18",
                width: "144",
                height: "20",
            },
            svg("path", {fill: "#fff", d: "m0,1.15v13.03h2.33v-4.31h2.83c2.64,0,4.48-1.76,4.48-4.34S7.86,1.15,5.26,1.15H0Zm2.33,1.91h2.31c1.68,0,2.63.87,2.63,2.48s-.98,2.46-2.64,2.46h-2.3V3.05Zm11.81,11.29c1.29,0,2.38-.56,2.93-1.52h.15v1.35h2.16v-6.75c0-2.09-1.43-3.32-3.97-3.32-2.35,0-3.98,1.1-4.16,2.83h2.11c.21-.68.91-1.05,1.94-1.05,1.21,0,1.86.55,1.86,1.54v.85l-2.55.15c-2.4.14-3.75,1.17-3.75,2.94s1.36,2.96,3.27,2.96Zm.66-1.73c-.99,0-1.71-.5-1.71-1.35s.59-1.28,1.84-1.36l2.23-.15v.8c0,1.17-1.01,2.06-2.37,2.06Zm7.86,5.14c2.21,0,3.27-.8,4.11-3.23l3.57-10.22h-2.37l-2.3,7.75h-.15l-2.31-7.75h-2.46l3.53,9.91-.12.46c-.29.93-.82,1.29-1.77,1.29-.15,0-.49,0-.61-.03v1.78c.14.03.75.04.89.04Zm27.38-13.45h-2.23l-1.64,7.42h-.15l-1.92-7.42h-2.14l-1.9,7.42h-.15l-1.64-7.42h-2.26l2.67,9.87h2.33l1.92-7.15h.15l1.92,7.15h2.36l2.67-9.87Zm2.9-1.65c.74,0,1.35-.59,1.35-1.33s-.61-1.33-1.35-1.33-1.34.6-1.34,1.33.61,1.33,1.34,1.33Zm-1.11,11.53h2.23V4.31h-2.23v9.87Zm5.53-12.29v2.48h-1.56v1.74h1.56v5.45c0,1.91.9,2.66,3.17,2.66.43,0,.85-.04,1.17-.1v-1.72c-.27.03-.44.05-.76.05-.94,0-1.36-.44-1.36-1.43v-4.91h2.11v-1.74h-2.11V1.89h-2.23Zm6.48,12.29h2.24v-5.74c0-1.45.82-2.41,2.29-2.41,1.3,0,1.99.78,1.99,2.3v5.84h2.24v-6.37c0-2.35-1.28-3.69-3.4-3.69-1.47,0-2.53.67-3,1.78h-.16V.49h-2.2v13.69Z"}),
            svg("path", {fill: "#fff", style: "fill-rule: evenodd", d: "m143.63,6.02c-.72-2.75-2.47-3.84-4.85-3.84-1.93,0-4.36,1.09-4.36,3.72,0,1.7,1.17,3.03,3.09,3.37l1.81.32c1.23.22,1.58.69,1.58,1.31,0,.71-.76,1.11-1.89,1.11-1.48,0-2.41-.53-2.55-2l-2.61.4c.41,2.85,2.96,4.02,5.27,4.02,2.18,0,4.51-1.25,4.51-3.78,0-1.72-1.05-2.97-3-3.33l-2-.36c-1.11-.2-1.48-.75-1.48-1.27,0-.67.72-1.09,1.71-1.09,1.26,0,2.14.42,2.18,1.82l2.61-.4Zm-58.07,4.32l2.71-7.86h3.19l-4.73,11.66h-2.37l-4.73-11.66h3.19l2.74,7.86Zm16.68-4.52c0,.93-.74,1.58-1.6,1.58s-1.6-.65-1.6-1.58.74-1.58,1.6-1.58,1.6.65,1.6,1.58h0Zm.49,4.12c-1.07,1.37-2.2,2.32-4.2,2.32-2.04,0-3.62-1.21-4.85-2.99-.49-.73-1.25-.89-1.81-.51-.51.36-.64,1.13-.16,1.8,1.71,2.57,4.07,4.06,6.83,4.06,2.53,0,4.51-1.21,6.05-3.23.58-.75.56-1.51,0-1.94-.51-.4-1.27-.26-1.85.48Zm7.1-1.66c0,2.38,1.4,3.64,2.96,3.64,1.48,0,3-1.17,3-3.64s-1.52-3.6-2.98-3.6c-1.58,0-2.98,1.11-2.98,3.6Zm0-4.18v-1.6h-2.9v15.68h2.9v-5.58c.97,1.29,2.22,1.84,3.64,1.84,2.65,0,5.25-2.06,5.25-6.3s-2.7-5.96-5-5.96c-1.83,0-3.09.83-3.89,1.92h0Zm13.93,4.18c0,2.38,1.4,3.64,2.96,3.64,1.48,0,3-1.17,3-3.64s-1.52-3.6-2.98-3.6c-1.58,0-2.98,1.11-2.98,3.6h0Zm0-4.18v-1.6h-2.9v15.68h2.9v-5.58c.97,1.29,2.22,1.84,3.64,1.84,2.65,0,5.25-2.06,5.25-6.3s-2.7-5.96-5-5.96c-1.83,0-3.09.83-3.89,1.92h0Z"})
        )
    }
};

export class VippsHurtigkasse extends HTMLElement {
    private _locale: Locale;
    private _svgShape: PayWithVippsButtonSvgShape = null;
    private _startBtn: HTMLElement = null;

    private _controller: VippsHurtigkasseController = new VippsHurtigkasseController();
    private _jQuery: any = (window as any).jQuery;
    private _pubSub: any = (window as any).PubSub;

    constructor(svgShape?: PayWithVippsButtonSvgShape) {
        super();
        this._svgShape = svgShape;
        this._locale = this._getKnownLocale();
        this.labels = determineTranslation(this.translations);
    }

    private translations = {
        en: {
            message: "Please wait...",
        },
        no: {
            message: "Vennligst vent...",
        }
    };

    private labels: { [key: string]: any };

    public connectedCallback(): void {
        this._renderStart();
    }

    public startCheckout(force: boolean = false): void {
        if (!force){
            const onBeforeStartCustomEvent = new CustomEvent("onbeforestart", {
                bubbles: true,
                cancelable: true,
                composed: true
              });

            const isOnBeforeCancelled = !this.dispatchEvent(onBeforeStartCustomEvent);
            if (isOnBeforeCancelled){
                return;
            }
        }

        if (this._jQuery && typeof this._jQuery.blockUI == 'function'){
            this._jQuery.blockUI({
                message: '<h2 style="margin-bottom:0">' + this.labels.message + '</h2>',
                css: {
                    border: 'none',
                    padding: '16px',
                    backgroundColor: '#000',
                    borderRadius: '6px',
                    opacity: .7,
                    color: '#fff',
                    width: '100%',
                    'max-width': '256px',
                    left: '50%',
                    'transform': 'translateX(-50%)'
                }
            });
        }

        const handleReponse = this._processConfirmedOrderResponse.bind(this);
        this._controller.startExpressCheckout().then(handleReponse);
    }

    private _getKnownLocale(): Locale {
        const languageId = this.getAttribute('lang') ?? window.globalSettings.language.toString();
        switch (languageId){
            case '3':
                return Locale.No;
            default:
                return Locale.En;
        }
    }

    private _renderStart(): void {
        this._startBtn = el('button', { type: 'button', style: 'background-color: #ff5b24', onclick: this._onclick.bind(this), title: 'Vipps Hurtigkasse', class: 'vipps-hurtigkasse-button' });
        const svgTag = this._computeSvgTag();
        this._startBtn.appendChild(svgTag);
        mount(this, this._startBtn);
    }

    private _onclick(evt: MouseEvent): void {
        evt.preventDefault();

        this.startCheckout();
    }

    private _processConfirmedOrderResponse(confirmedOrderResponse: IConfirmedOrderResponse): void {
        const error: string = confirmedOrderResponse == null ? 'Empty response' : confirmedOrderResponse.errorMsg;
        if (error) {
            if (this._jQuery && typeof this._jQuery.unblockUI == 'function'){
                this._jQuery.unblockUI();
            }

            if (this._pubSub && typeof this._pubSub.publish == 'function'){
                this._pubSub.publish('notification.error', error);
            }

            console.error(error);
        } else {
            window.location.href = confirmedOrderResponse.paymentProviderInternalData;
        }
    }

    private _computeSvgTag(): SVGElement {
        const svgTagProvider = btnSvg[this._locale][this._getSvgShape()];
        const svgTag = svgTagProvider();
        return svgTag;
    }

    private _getSvgShape(): PayWithVippsButtonSvgShape{
        // If passed svg shape in constructor - has higer priority over checkers below.
        if(this._svgShape){
            return this._svgShape;
        }

        // Enum based attribute checker
        if(this.hasAttribute('svg-shape')){
            let matchingShape = PayWithVippsButtonSvgShape.Logo; // Set default value. To ignore btn-shape check.
            const configuredBtnSvgShape = this.getAttribute('svg-shape');

            for (const value of Object.values(PayWithVippsButtonSvgShape)) {
                if (value.toLowerCase() === configuredBtnSvgShape.toLowerCase()) {
                    matchingShape = PayWithVippsButtonSvgShape[value];
                    break;
                }
            }
            return matchingShape;
        }

        // Constant based attribute checker - Deprecated.
        const configuredBtnShape = this.getAttribute('btn-shape');
        if(configuredBtnShape){
            const computedShapeName = configuredBtnShape && PayWithVippsButtonShape.hasOwnProperty(configuredBtnShape)
                ? PayWithVippsButtonShape[configuredBtnShape as keyof typeof PayWithVippsButtonShape] : PayWithVippsButtonSvgShape.Logo;

            return computedShapeName;
        }
    }
}

customElements.define('vipps-hurtigkasse', VippsHurtigkasse);